<template>
	<AddEditForm :headerTitle="$t('pages.product.addUnit')" :onGoBack="onGoBack" :showDelete="false">
		<template v-slot:headerAddEdit>
			<button type="button" name="add-unit" class="btn btn-primary" @click="onSave">
				{{ $t('common.save') }}
			</button>
			<button type="button" name="add-unit-continue" class="btn btn-primary" @click="onSaveContinue">
				{{ $t('common.saveAndContinue2') }}
			</button>
		</template>
		<template #content>
			<Accordion :headerTitle="$t('common.generalInfo')" >
				<template v-slot:body>
					<CForm>
						<CInput :placeholder="$t('pages.product.enterUnitName')" v-model="unitName" :is-valid="validator" required horizontal>
							<template #label>
								<div class="col-sm-3 col-form-label">
									{{ $t('pages.product.unitName') }}
									<small class="requiredInput">(*)</small>
								</div>
							</template>
						</CInput>						
					</CForm>
				</template>
			</Accordion>
		</template>
	</AddEditForm>
</template>

<script>	
    import i18n from '@/plugins/i18n' 
    import Accordion from '@/components/Accordion.vue'
    import AddEditForm from '@/components/AddEditForm.vue'

	export default {
		name: 'AddUnit',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
				unitName: ''
			}
		},
		computed: {
            
		},
		components: {
            Accordion, AddEditForm
		},
		methods: {
            onSave() {
                this.onSaveNewUnit(false);
			},
			onSaveContinue() {
                this.onSaveNewUnit(true);
            },
            onGoBack() {
                this.$router.push('/units/list');
            },
			validator(val) {				
				return val ? val.length > 0 : false
            },						
            async onSaveNewUnit(isSaveAndContinue) {
				
                if (!this.validator(this.unitName))
				{					
                    this.$store.dispatch('alert/error', this.$t('pages.product.enterUnitName'));
					return;
				}                

				let isSuccessful = false;
                var response = await this.$store.dispatch("unit/addUnit", this.unitName);

                if (response.success == true) {
                    this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.product.noticeAddUnitSuccess') }, { root: true });
                    isSuccessful = true;
                }
                else {
                    let message = i18n.t('pages.product.noticeAddUnitFailed');
                    if (response.errorMessage === 'Existed') 
                        message = i18n.t('pages.product.noticeExistedUnit');

                    this.$store.dispatch('alert/error', message, { root: true });
				}
               
                if (isSuccessful) {
					if (isSaveAndContinue) {
                        this.unitName = '';
					}
					else {
                        this.onGoBack();
                    }
                }
			},			
		},		
	}
</script>